import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import App from "./App";
import store from "./store";

import "./index.css";
import ToastManager from "./views/notifications/toasts/ToastManager";
import { ConfirmProvider } from "./context/ConfirmProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <Provider store={store}>
      <ConfirmProvider>
        <App />
      </ConfirmProvider>
      <ToastManager />
    </Provider>
  </React.Fragment>
);
