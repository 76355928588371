import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sidebarShow: true,
  theme: 'light',
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setSidebarShow: (state, action) => {
      state.sidebarShow = action.payload
    },
    setTheme: (state, action) => {
      state.theme = action.payload
    },
    set: (state, action) => {
      return { ...state, ...action.payload }
    },
  },
})

export const { setSidebarShow, setTheme, set } = uiSlice.actions

export default uiSlice.reducer
