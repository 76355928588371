import { configureStore } from '@reduxjs/toolkit'
import uiSlice from './uiSlice'
import authSlice from './authSlice'
import userSlice from './userSlice'
import toastSlice from './toastSlice'
import eventSlice from './eventSlice'
import orderSlice from './orderSlice'
import mediaSlice from './mediaSlice'
import mediaLinkSlice from './mediaLinkSlice'
import dashboardSlice from './dashboardSlice'
import complimentOrdersSlice from './complimentOrdersSlice'

const store = configureStore({
  reducer: {
    ui: uiSlice,
    auth: authSlice,
    users: userSlice,
    toast: toastSlice,
    events: eventSlice,
    orders: orderSlice,
    media: mediaSlice,
    mediaLink: mediaLinkSlice,
    dashboard: dashboardSlice,
    complimentOrders : complimentOrdersSlice
  },
})

export default store
