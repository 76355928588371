import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../apis/axiosInstance';
import { addToast } from './toastSlice';
import translation from '../helpers/translation';
import copy from 'copy-to-clipboard';

export const fetchMediaLinks = createAsyncThunk('/media-link', async (_,{dispatch}) => {
  try {
    const response = await axiosInstance.get('/media-link');
    dispatch(addToast({ text: translation.link_copied, color: 'success' }));
    // console.log("fetch media link response :", response);
    return response.data;
  } catch (error) {
    console.error("fetch media link error:",error);
    throw error;
  }
});

const initialState = {
  link: "",
  loading: false,
  error: null
};

const mediaLinksSlice = createSlice({
  name: 'mediaLinks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMediaLinks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMediaLinks.fulfilled, (state, action) => {
        state.loading = false;
        state.link = action.payload.data;
        // try{
          copy(action.payload.data.link);
          // navigator.clipboard.writeText(action.payload.data.link);
        // } catch (err){
        //   console.log("getting error while copying!")
        // }
      })
      .addCase(fetchMediaLinks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});

export default mediaLinksSlice.reducer;