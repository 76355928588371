// src/components/ToastManager.js

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CToast, CToastBody, CToastClose, CToaster } from '@coreui/react';
import { removeToast } from '../../../store/toastSlice';

const ToastManager = () => {
  const toasts = useSelector((state) => state.toast.toasts);
  const dispatch = useDispatch();

  useEffect(() => {
    if (toasts.length > 0) {
      const timer = setTimeout(() => {
        dispatch(removeToast(0));
      }, toasts[0].duration || 5000);

      return () => clearTimeout(timer);
    }
  }, [toasts, dispatch]);

  return (
    <CToaster placement="top-end">
      {toasts.map((toast, index) => (
       <div key={index}>
         <CToast
          autohide={toast.autohide}
          color={toast.color}
          className="text-white align-items-center m-2"
          visible={true}
          delay={toast.duration}
        >
          <div className="d-flex">
            <CToastBody>{toast.text}</CToastBody>
            <CToastClose className="me-2 m-auto" white />
          </div>
        </CToast>
       </div>
      ))}
    </CToaster>
  );
};

export default ToastManager;
