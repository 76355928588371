import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../apis/axiosInstance';
import { addToast } from './toastSlice';
import translation from '../helpers/translation';


export const addEvent = createAsyncThunk('events/addEvent', async (eventData, {dispatch}) => {
  try {
    const formData = new FormData();
    formData.append("name", eventData.name);
    formData.append("date_time", eventData.date_time);
    formData.append("organizer_name", eventData.organizer_name);
    formData.append("phone", eventData.phone);
    formData.append("email", eventData.email);
    formData.append("address", eventData.address);
    formData.append("template", eventData.template);
    const response = await axiosInstance.post('/events', formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch(addToast({ text: response?.data?.meta?.message, color: 'success' }));
    return response.data;
  } catch (error) {
    dispatch(addToast({ text: error?.response?.data?.meta?.message, color: 'danger' }));
    console.error("addEvent error:",error);
    throw error;
  }
});

export const fetchEvents = createAsyncThunk('events/fetchEvents', async (_,{dispatch}) => {
  try {
    const response = await axiosInstance.get('/events');
    // dispatch(addToast({ text: response?.data?.meta?.message, color: 'success' }));
    return response.data;
  } catch (error) {
    // dispatch(addToast({ text: error?.response?.data?.meta?.message, color: 'danger' }));
    console.error("fetchEvents error:",error);
    throw error;
  }
});

export const deleteEvent = createAsyncThunk('events/deleteEvent', async (eventId,{dispatch}) => {
  try {
    const response = await axiosInstance.delete(`/events/${eventId}`);
    dispatch(addToast({ text: response?.data?.meta?.message, color: 'success' }));
    return eventId;
  } catch (error) {
    dispatch(addToast({ text: error?.response?.data?.meta?.message, color: 'danger' }));
    console.error("deleteEvent error:",error);
    throw error;
  }
});

export const editEvent = createAsyncThunk('events/editEvent', async (eventData,{dispatch}) => {
  try {
    // const {id, ...restData} = eventData
    const formData = new FormData();
    formData.append("name", eventData.name);
    formData.append("date_time", eventData.date_time);
    formData.append("organizer_name", eventData.organizer_name);
    formData.append("phone", eventData.phone);
    formData.append("email", eventData.email);
    formData.append("address", eventData.address);
    if (eventData.template) {
      formData.append("template", eventData.template);
    }

    const response = await axiosInstance.put(`/events/${eventData.id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch(addToast({ text: response?.data?.meta?.message, color: 'success' }));
    return response.data;
  } catch (error) {
    dispatch(addToast({ text: error?.response?.data?.meta?.message, color: 'danger' }));
    console.error("editEvent error:",error);
    throw error;
  }
});

const initialState = {
  events: [],
  loading: false,
  error: null,
};

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addEvent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addEvent.fulfilled, (state, action) => {
        state.loading = false;
        state.events.unshift(action.payload.data);
      })
      .addCase(addEvent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchEvents.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.loading = false;
        state.events = action.payload.data;
      })
      .addCase(fetchEvents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteEvent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteEvent.fulfilled, (state, action) => {
        state.loading = false;
        state.events = state.events.filter(event => event.id !== action.payload);
      })
      .addCase(deleteEvent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || translation.could_not_delete_event;
      })
      .addCase(editEvent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editEvent.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.events.findIndex(event => event.id === action.payload.data.id);
        if (index !== -1) {
          state.events[index] = action.payload.data;
        }
      })
      .addCase(editEvent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || translation.could_not_edit_event;
      });
  },
});

export default eventsSlice.reducer;
