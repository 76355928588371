import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../apis/axiosInstance';
import { addToast } from './toastSlice';
import translation from '../helpers/translation';

export const fetchOrders = createAsyncThunk('orders/fetchOrders', async (_,{dispatch}) => {
  try {
    const response = await axiosInstance.get('/orders');
    // console.log("fetchEvents response :", response);
    // dispatch(addToast({ text: response?.data?.meta?.message, color: 'success' }));
    return response.data;
  } catch (error) {
    // dispatch(addToast({ text: error?.response?.data?.meta?.message, color: 'danger' }));
    console.error("fetchEvents error:",error);
    throw error;
  }
});

export const fulfillOrders = createAsyncThunk('orders/fulfill', async (data,{dispatch}) => {
  try {
    const response = await axiosInstance.post('/orders/fulfill', data);
    // console.log("fulfillOrders response :", response);
    dispatch(addToast({ text: response?.data?.meta?.message, color: 'success' }));
    dispatch(updateOrderFulfilledStatus(data.order_id));
    return response.data;
  } catch (error) {
    dispatch(addToast({ text: error?.response?.data?.meta?.message, color: 'danger' }));
    console.error("fetchEvents error:",error);
    throw error;
  }
});

export const generateOrderLink = createAsyncThunk('orders/generateLink', async ( data,{dispatch}) => {
  try {
    if(data.openLink){
      dispatch(setOrderId(data.order));
    }
    const response = await axiosInstance.get(`/orders/generateLink/${data.order}`);
    return response.data;
  } catch (error) {
    dispatch(addToast({ text: error?.response?.data?.meta?.message, color: 'danger' }));
    console.error("generateLink error:",error);
    throw error;
  }
});

const initialState = {
  orders: [],
  generateMediaLink: "",
  loading: false,
  error: null,
  order_id: null,
  fulfill_loading: false,
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    clearGenerateMediaLink: (state) => {
      state.generateMediaLink = "";
    },
    setOrderId: (state, action) => {
      state.order_id = action.payload;
    },
    updateOrderFulfilledStatus: (state, action) => {
      const updateOrders = action.payload;
      state.orders = state.orders.map(order => {
        if (updateOrders.includes(order.id)) {
          return { ...order, fulfilled_status: true };
        }
        return order;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.orders = action.payload.data;
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fulfillOrders.pending, (state) => {
        state.fulfill_loading = true;
        state.error = null;
      })
      .addCase(fulfillOrders.fulfilled, (state, action) => {
        state.fulfill_loading = false;
      })
      .addCase(fulfillOrders.rejected, (state, action) => {
        state.fulfill_loading = false;
        state.error = action.error.message;
      })
      .addCase(generateOrderLink.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(generateOrderLink.fulfilled, (state, action) => {
        state.loading = false;
        if(state.order_id){
          window.open(action.payload.data.link,"_self");
          state.order_id = null;
        } else {
          state.generateMediaLink = action.payload.data.link;
        }
      })
      .addCase(generateOrderLink.rejected, (state, action) => {
        state.loading = false;
        state.order_id = null;
        state.error = action.payload || translation.could_not_generate_link;
      })
  },
});

export const { clearGenerateMediaLink, setOrderId, updateOrderFulfilledStatus } = ordersSlice.actions;
export default ordersSlice.reducer;
