import React, { createContext, useContext, useState, useCallback } from "react";
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
  CSpinner
} from "@coreui/react";
import translation from "../helpers/translation";

const ConfirmContext = createContext();

export const useConfirm = () => useContext(ConfirmContext);

export const ConfirmProvider = ({ children }) => {
  const [confirmDialog, setConfirmDialog] = useState({
    show: false,
    message: "",
    onConfirm: () => {},
    onClose: () => {},
    isPrompt: true,
    loading: false,
  });

  const showConfirmDialog = useCallback((message, onConfirm) => {
    setConfirmDialog({
      show: true,
      message,
      onConfirm: () => {
        setConfirmDialog((prev) => ({ ...prev, loading: true }));
        onConfirm && onConfirm().finally(() => {
          setConfirmDialog((prev) => ({ ...prev, show: false, loading: false }));
        });
      },
      onClose: () => setConfirmDialog((prev) => ({ ...prev, show: false })),
      isPrompt: onConfirm,
    });
  }, []);

  const value = {
    showConfirmDialog,
  };

  return (
    <ConfirmContext.Provider value={value}>
      {children}
      <CModal visible={confirmDialog.show} onClose={confirmDialog.onClose}>
        <CModalHeader closeButton>{translation.confirm_action}</CModalHeader>
        <CModalBody>{confirmDialog.message}</CModalBody>

        {confirmDialog.isPrompt && (
          <CModalFooter>
            <CButton color="secondary" onClick={confirmDialog.onClose}>
            {translation.cancel}
            </CButton>
            <CButton color="primary" onClick={confirmDialog.onConfirm}>
              {translation.confirm}
              {confirmDialog.loading && (<CSpinner as="span" size="sm" aria-hidden="true" className="ml-2" />)}
            </CButton>
          </CModalFooter>
        )}

        {!confirmDialog.isPrompt && (
          <CModalFooter>
            <CButton color="primary" onClick={confirmDialog.onClose}>
            {translation.ok}
            </CButton>
          </CModalFooter>
        )}
      </CModal>
    </ConfirmContext.Provider>
  );
};
